/* eslint-disable */

// $(function () {
function importJQ () {
  var wW = $(window).width();
  var wH = $(window).height();
  $(window).on('load resize', function () {
    wW = $(window).width();
    wH = $(window).height();
  });

  /* --------------------------頁面最小高度-------------------------- */
  function pageHeight() {
    var minH = wH - $('header').outerHeight() - $('footer').outerHeight();
    $('.container').css('min-height', minH);
    var containerH = $('.container').outerHeight();
    //if height now > minH , content H = min H
    if(containerH > minH) {
      $('.container').css('min-height', minH);
      containerH = minH;
    }
  }
  $(window).on('load resize', function () {
    pageHeight();
  });
  $(document).ready(function(){
    pageHeight();
  });

  /* -----------------------------選單----------------------------- */
  //選單_大網第二層
  $('.header-menu-first .folder').mouseenter(function () {
    if( wW > 768 ) {
      $(this).siblings('.header-menu-sec').slideDown();
    }
  });
  $('.header-menu-sec').mouseleave(function() {
    if( wW > 768 ) {
      $(this).slideUp();
    }
  });
  //選單_小網
  $('.header-nav-btn').click(function() {
    if(wW < 767 && $('.wrapper').hasClass('header-nav-open') == true){
      $('.wrapper').removeClass('header-nav-open');
    }else {
      $('.wrapper').addClass('header-nav-open');
    }
  });
  //選單_大小網狀態更新
  $(window).on('load resize', function() {
    if (wW > 768) {
      $('.wrapper').removeClass('header-nav-open');
      $('.header-menu-sec').css('display','none');
    }if (wW < 768) {
      $('.header-menu-sec').css('display','block');
    }
  });
  
  /* -----------------------------頁面----------------------------- */
  /* 滿版區塊 */
  $(window).on('load resize', function() {
    var containerInnerH = $('.container').height() - $('.breadcrumbs').outerHeight();
    $('.js_fullArea').css('min-height', containerInnerH );
  });
  // $(document).ready(function(){
  //   js_fullArea();
  // });
  // $(window).on('resize', function () {
  //   js_fullArea();
  // });
}
export default importJQ
// });